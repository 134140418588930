  div.skeleton-tile-container {
    width: 60vw;
    min-height: 12vh;
    height: 14vh;
    max-height: 15vh;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: rgba(188, 208, 228, 0.436);
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }

  div.skeleton-tile-container:hover {
    box-shadow: 0px 0px 5px #979cc6;
    transition: .5s;
  }

  li.skeleton-tile-object {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }

  div.tile-top-container {
    justify-content: space-between;
    display: flex;
    vertical-align: center;
  }

  div.title-author-container {
    display: flex;
  }

  div.title-container, div.author-container {
    width: 20vw;
  }

  h1.title, h1.title-author-divider, h1.author {
    font-weight: 400;
    font-size: 2vw;
  }

  h1.title, h1.author {
    letter-spacing: .05vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    transition: .5s;
  }

  #gen-skeleton-link, #gen-title-link, #gen-profile-link {
    text-decoration: none;
    color: black;
  }

  h1.title-author-divider {
    margin-left: 1vw;
    margin-right: 1.5vw;
    letter-spacing: -.25vw;
  }

  h1.title:hover, h1.author:hover {
    color: #979cc6;
    transition: .5s;
    cursor: pointer;
  }

  h3.bone-counter {
    align-self: center;
  }

  div.skeleton-body-likes-container {
    display: flex;
  }

  .skeleton-body-container {
    margin-top: 2vh;
    font-style: italic;
    height: 9vh;
    width: 50vw;
    color: rgb(42, 42, 42);
    margin-bottom: -4vh; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25vw;
    /* margin-bottom: -10.5vw; */
    display: -webkit-box;
    /* margin-bottom: 3vw; */
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    line-clamp: 3;
    line-height: 3vh;
  }

  .skeleton-body-container > p {
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-bottom: -10.5vw; */
    display: -webkit-box;
    /* margin-bottom: 3vw; */
    -webkit-line-clamp: 2;
      /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  div.skeleton-likes-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 0;
    margin-top: .75vh;
    text-align: center;
    align-content: center;
    vertical-align: center;
  }

  button.vote-button {
    aspect-ratio: 1 / 1;
    background: none;
    border: none;
    padding-top: 0vh;
    padding-bottom: 0vh;
  }

  p.skeleton-like-caption{
    font-size: 1vw;
    margin-top: 0.75vh;
  }

  div.skeleton-tags-container {
    width: 50vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
    max-height: 2vh;
    align-items: center;
    background-color: white;
    padding-top: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-bottom: 2vh;
    border-radius: 25px;
  }

  ul.skeleton-tags {
    border-radius: 25px;
    width: 50vw;
    margin-top: 5vh;
    max-height: 2vh;
    align-items: center;
    display: flex;
    align-items: row;
    height: 2vh;
    margin-bottom: 2vh;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-bottom: 2vh;
    background-color: white;
    display: -webkit-box;
    margin-top: 7vh;
      /* margin-bottom: 3vw; */
    -webkit-line-clamp: 1;
      /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: horizontal;
    margin-bottom: 3vh;
  }

  p.ind-tag {
    font-style: italic;
    margin-right: 1vw;
    font-size: 1.25vw;
    filter: opacity(50%);
  }

  p.ind-tag:hover {
    font-style: normal;
    filter: opacity(100%);
    transition: 1s;
    cursor: pointer;
  }

  .skeleton-like-count {
    font-size: 2vw;
    margin-top: .5vh;
  }