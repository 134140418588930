.main-page {
  /* background-color: rgb(35, 51, 91); */
  font-family: Arial, sans-serif;
  /* color: white; */
  display: flex;
  flex-direction: column;
}

.goofy-greeting-container {
  width: 67.85vw;
  text-align: left;
}

#goofy-greeting {
  font-weight: 600;
  font-size: 3vw;
  text-align: left;
  margin-bottom: 3vh;
}

hr#main-page-hr {
  margin-top: 4vh;
  margin-bottom: 1vh;
  width: 60%;
  align-self: flex-start;
}

.main-content {
  font-family: Arial, sans-serif;
  align-items: center;
  display: flex;
  margin-top: -4vh;
}
