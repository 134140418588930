.main-page, .index-page {
  font-family: Inter, sans-serif;
  display: flex;
  padding-bottom: 2rem;
  padding-left: 3vw;
  padding-right: 3vw;
}

footer {
  position: relative;
  margin-top: 4rem;
  height: 4rem;
  width: 100%;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
}
