  div.trending-bar-container {
    font-family: Inter, sans-serif;
    position: fixed;
    width: 15vw;
    height: 67vh;
    margin-left: 4vw;
    margin-top: 4vh;
    left: 69.5vw;
    bottom: 4vw;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: rgba(188, 208, 228, 0.436);
    border: none;
    align-self: flex-start;
    align-items: flex-start;
    /* vertical-align: top; */
    border-color: black;
    border-width: 3px;
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }

  h1#trending-bar-header {
    text-align: center;
    font-weight: 600;
  }

  ol.trending-bar-list {
    width: 15vw;
    height: 60vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 2vh;
    padding-bottom: 4vh;
    list-style-type: decimal;
    /* background-color: rgba(188, 208, 228, 0.436); */
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }

p#trending-title {
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-bottom: -10.5vw; */
  display: -webkit-box;
  /* margin-bottom: 3vw; */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1vh;
  transition: .5s;
}

p#trending-title:hover {
  color: #979cc6;
  text-shadow: 0px 0px 5px #979cc621;
  transition: .5s;
  cursor: pointer;
}

p#trending-likes {
  margin-bottom: 2vh;
}