div.comment-panel-container {
  width: 47.5vw;
  display: flex;
  flex-direction: column;
  background-color: rgba(188, 208, 228, 0.436);
  height: max-content;
  padding-left: 3vw;
  padding-right: .5vw;
  padding-top: 4.5vh;
  padding-bottom: 2.5vh;
  border-radius: 10px;
  margin-left: 4vw;
  margin-right: auto;
  margin-bottom: 4vh;
  align-items: left;
  font-family: Inter, sans-serif;

}

h3.commenter-username, #link-to-profile {
  font-weight: 700;
  font-size: 1.5vw;
  text-decoration: none;
  color: black;
  transition: .5s;
}

h3.commenter-username:hover {
  color: #979cc6;
  transition: .5s;
}

p#comment-timestamp {
  margin-top: 1vh;
  font-weight: 300;
  font-style: oblique;
  font-size: 1vw;
}

div.comment-body {
  margin-top: .75vw;
  margin-left: 1vw;
  margin-bottom: 2vh;
  opacity: 55%;
  font-style: oblique;
  font-size: 1.25vw;
  width: 42vw;
}

div.owner-comment-class-actions-container {
  display: flex;
  margin-left: 0;
  margin-right: auto;
}

.comment-delete-button, .comment-update-button {
  transition: 0.5s;
  width: min-content;
  height: min-content;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: 1vw;
  margin-left: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-update-input {
  transition: 0.5s;
  width: min-content;
  height: 14vh;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: 1vw;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

textarea#comment-update-input {
  margin-top: .75vw;
  margin-left: 0vw;
  margin-bottom: 2vh;
  opacity: 55%;
  font-style: oblique;
  font-size: 1.25vw;
  width: 42vw;
  height: min-content;
  min-height: 3vh;
}

.comment-save-update-button {
transition: 0.5s;
  width: max-content;
  height: min-content;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: 1vw;
  margin-left: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;  
}

.comment-delete-button:hover, .comment-update-button:hover, .comment-save-update-button:hover {
  cursor: pointer;
  transition: 0.5s;
  background-color: #34374d;
  color: white;
}