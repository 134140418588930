div.about-container {
  /* align-items: center; */
  text-align: center;
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  align-content: center;
  width: 90vw;
}

h1.current-user-greeting, h1.about-sub-greeting, ul.current-user-options, p.explanatory-text, blockquote, a#wiki-link, h2.developer-name {
  font-family: Inter, sans-serif;
}

#about-logo {
  width: 15vw;
  margin-bottom: -2.5vh;
}

#about-logo:hover {
  animation: wiggle 2.5s forwards;
  animation-iteration-count: infinite;
}

div#about-grid-container {
  display: inline-grid;
  grid-template-columns: 3;
  column-gap: 2vw;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

div#about-1 {
  grid-column-start: 1;
  grid-column-end: 1;
}

div#about-vr-container {
  grid-column-start: 2;
  grid-column-end: 2;
  width: 4vw;
  border-left: black;
  border-left-width: 1vw;
  display: flex;
}

hr#about-vr {
  opacity: 45%;
  margin-top: 4vh;
}

div#about-2 {
  grid-column-start: 3;
  grid-column-end: 3;
}

blockquote#wiki-quote {
  font-style: italic;
  text-decoration: none;
  transition: .25s;
  background-color: none;
  text-align: left;
  line-height: 3.2vh;
  opacity: 45%;
}

a#wiki-link {
  font-style: italic;
  text-decoration: none;
  transition: .25s;
  background-color: none;
  text-align: right;
  align-items: flex-end;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 0vw;
}

a#wiki-link:hover {
  font-style: italic;
  color: #979cc6;
  cursor: pointer;
  transition: 0.2s;
  /* font-weight: 700; */
  transition: .25s;
  text-shadow: 0px 0px 2px #ccd4ff;
  background-color: none;
}

p#mini-descriptor {
  margin-bottom: .5vh;
}

li.project-list-link, li.project-list-link > a {
  font-style: italic;
  text-decoration: none;
  transition: .25s;
  background-color: none;
  text-align: left;
  align-items: flex-end;
  margin-bottom: 1vh;;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 0vw;
  color: none;
}

li.project-list-link > a:hover {
  font-style: italic;
  color: #979cc6;
  cursor: pointer;
  transition: 0.2s;
  /* font-weight: 700; */
  transition: .25s;
  text-shadow: 0px 0px 2px #ccd4ff;
  background-color: none;
}

br.link-height-space {
  line-height: .1vh;
}

h1.current-user-greeting {
  font-size: 3vw;
}

h1.about-sub-greeting {
  margin-top: 3vh;
  font-size: 3vw;
  margin-bottom: 3vh;
}

span#current-user-username {
  /* font-style: oblique; */
  font-weight: 600;
}

hr.current-user-hr {
  width: 33vw;
  margin-left: auto;
  margin-right: auto;
}


img#skull-buddy-ani-about {
  object-fit: contain;
  width: 20vw;
  height: 20vw;
  transition: 0.5s;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #979cc6;
}

li.user-option, #current-user-profile-link, #edit-current-user-profile-link {
  transition: 0.2s;
  text-decoration: none;
  color: black;
}

li.user-option:hover {
  color: #979cc6;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: underline;
  font-weight: 700;
}

.developer-name {
  font-weight: 600;
  margin-bottom: 1vh;
}

div#social-media-button-div {
  display: flex;
  justify-content: space-around;
}

img.social-media-button-img {
  width: 5vw;
  height: 5vw;
  transition: .5s;
}

img.social-media-button-img:hover {
  filter: brightness(225%);
  transition: .5s;
}

img#skull-buddy-ani-about:hover {
  transition: .5s;
  box-shadow: 0px 0px 10px #979cc6;
  animation: hueswitch 5s forwards;
  animation-iteration-count: infinite;
}

div#current-user-whitespace > br.whitespace {
  margin-bottom: 20vw;
} 

div.cat-activate {
  width: 80vw;
  object-fit: cover;
}

img.danger-image {
  display: none;
  position: absolute;
  align-self: center;
}

img.cat-activate {
  transition: .5s;
  display: flex;
  position: absolute;
  bottom: -100vh;
  left: 5.05vw;
  scale: 270%;
  width: 80vw;
  z-index: 9999;
  align-self: center;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

h1.silent-text {
  display: none;
}

h1.danger-text {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 4vw;
  display: flex;
  color: white;
  position: absolute;
  z-index: 10002;
} 

h1.danger-text:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

h1#danger-text-1 {
  text-align: left;
  left: 3vw;
  bottom: 65vh;
  text-shadow: 0 0 2px black;
}

h1#danger-text-2 {
  text-align: right;
  margin-right: 5vw;
  left: 3vw;
  bottom: -30vh;
  text-shadow: 0 0 5px black;
}

h1#danger-text-3 {
  text-align: center;
  margin-right: 5vw;
  left: 3vw;
  bottom: -120vh;
  text-shadow: 0 0 2px black;
}

hr#about-hr-2 {
  width: 40%;
}



div#about-grid-container-2 {
  display: inline-grid;
  width: 90vw;
  grid-template-columns: 5;
  column-gap: 1vw;
  margin-top: 1vh;
  margin-bottom: 3vh;
  text-align: center;

}

div.about-container-2 {
  width: 25vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
}

div#developer-text {
  display: flex;
  flex-direction: column;
}

.developer-picture {
  transition: .5s;
  width: 20vw;
  height: 20vw;
  margin-bottom: 2vh;
  border-radius: 50%;
  object-fit: cover;
}

.developer-picture:hover {
  transition: .5s;
  box-shadow: 0px 0px 20px #979cc6;
}

.developer-bio {
  display: flex;
  text-align: justify;
  line-height: 3vh;
  flex-direction: column;
}

#daphne-about {
  grid-column-start: 1;
  grid-column-end: 1;
}



#about-vr-container-2 {
  grid-column-start: 2;
  grid-column-end: 2;
  border-left: black;
  border-left-width: 4vw;
  opacity: 100%;
  display: flex;
}

#andrea-about {
  grid-column-start: 3;
  grid-column-end: 3;
}

#about-vr-container-3 {
  grid-column-start: 4;
  grid-column-end: 4;
  border-left: black;
  border-left-width: 4vw;
  opacity: 100%;
  display: flex;
}

#nathan-about {
  grid-column-start: 5;
  grid-column-end: 5;
}

p.explanatory-text {
  text-align: justify;
  line-height: 3.2vh;
  margin-bottom: 3.5vh;
}

@keyframes hueswitch {
  0% { filter: hue-rotate(0turn); }
  50% { filter: hue-rotate(1turn) }
  100% { filter: hue-rotate(2turn)}

}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}