.edit-modal {
  /* text-align: center; */
  align-self: center;
  margin-left: 10vw;
  margin-right: auto;
  padding-top: 5vh;
  /* padding-bottom: 4vh; */
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  transition: .5s;

  /* height: 50vh; */

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  overflow: scroll;

  /* flex-shrink: 3; */
  text-align: center;
  position: fixed;
  transform: translateX(12%);
  top: 10vh;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  height: 80vh;
  /* height: max-content; */
  color: #000;
  z-index: 900;

}

.edit-modal-form{
  width: 78vw;
  height: 75vh;
  margin-left: auto;
  margin-right: auto;
}

.edit-modal-background {
  width: 100vw;
  height: 100vh;
  z-index: 200;
  bottom: 0;
  background-color: #000;
  opacity: .7;
  position: fixed;
  top: 0;
  right: 0;
  left: 0; 
}

.resModalCloseBtn {
  width: 20px;
  height: 20px;
  margin: 14px;
  z-index: 301;
  position: absolute;
  top: 5px;
  right: 5px;
  display: inline-block;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-size: 20px auto;
  background-position: 50%;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

h2.form-title {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 3vw;
  text-align: center;
}

.edit-skellie-label {
  margin-bottom: -5vh;
}

h2.edit-skellie-label-text {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  font-weight: 400;
  text-align: left;
  margin-left: 15vw;
  margin-bottom: -0.5vh;
}

input.edit-skellie-input {
  width: 45vw;
  height: 2.75vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5vh;
  align-items: center;
  /* justify-content: center; */
  /* justify-items: center; */
  background-color: #30303000;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  font-weight: 400;
  border: #c3c3c3;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

textarea.edit-skellie-input {
  margin-left: auto;
  margin-right: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-color: #30303000;
  border-radius: 15px;
  width: 45vw;
  height: 3em;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border: #c3c3c3;
  border-style: solid;
  border-width: 1px;
  align-self: center;
  /* margin-left: 2vw;
    margin-right: 2vw; */
  z-index: 0;
}

#edit-collaborator-select {
  margin-left: auto;
  margin-right: auto;
  /* width: 40em; */
  height: 10vh;
  /* padding-left: 2vw;
  padding-right: 2vw; */
  padding-bottom: 5vh;
  border-radius: 25px;
}

 #collaborator-select {
    margin-left: auto;
    margin-right: auto;
    width: 45vw;
    /* max-width: 27em; */
    height: 5em;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 5vh;
  }

  input.skellie-input, textarea.skellie-input {
    align-items: center;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    margin-bottom: -2vh;
  }

  div.multiselect-container#collaborator-select {
    margin-top: -2vh;
  }

  div.multiselect-container {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 4vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
}

div.search-wrapper.searchWrapper {
  width: inherit;
}

div.multiselect-container {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* width: 40vw; */
  height: 8vh;
  vertical-align: middle;
  padding-top: 1vh;
  /* padding-left: 2vw;
  padding-right: 2vw; */
  padding-bottom: 5vh;
  border-radius: 25px;
}

div.search-wrapper.searchWrapper {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  height: 12vh;
  /* margin-left: auto; */
  /* margin-right: 5vw; */
}

div.multiselect-container, ul.optionContainer {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: auto;
  margin-right: auto;
  width: 48vw;
  /* width: 20vw; */
  height: 20vh;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
}
div.multiselect-container, ul.optionContainer {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 48vw;
  /* width: 20vw; */
  height: 20vh;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
}

div.edit-collaborator-select.multiselect-container.multiSelectContainer {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* align-self: center; */
  height: 40vh;
  /* margin-left: auto;
  margin-right: auto; */
  /* text-align: center; */
  /* width: 40vw; */
}

/* div.multiselect-container, div.search-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 25em;
  height: 15em;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
} */

input#edit-skellie-submit-button {
  margin-top: 6vh;
  margin-bottom: 2vh;
}