div.general-profile-container {
  /* align-items: center; */
  text-align: center;
  display: flex;
  flex-direction: column;
}

div.profile-banner, img.profile-banner {
  width: 95vw;
  height: 30vh;
  max-height: 40vh;
  object-fit: cover;
  /* border: solid; */
  /* border-width: 2px; */
  margin-left: auto;
  margin-right: auto;
  align-self: center;

}

img#banner-image {
  /* scale: 90%; */
  object-fit: cover;
}



div.profile-icon-border {
    width: 17vw;
    height: 17vw;
    background-color: rgb(255, 255, 255);
    border-width: 2vw;
    border: none;
    position: absolute;
    top: 25vh;
    left: 40.75vw;
    max-height: 40vh;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgb(206, 206, 206);
}

div.profile-icon {
  width: 16vw;
  height: 16vw;
  background-color: #fef8e9;
  border-width: 4vw;
  border: none;
  border-color: none;
  position: absolute;
  left: .5vw;
  bottom: .5vw;
  max-height: 40vh;
  object-fit: cover;
  border-radius: 50%;
}

img.profile-icon {
  width: 10vw;
  height: 10vw;
  max-height: 40vh;
  object-fit: cover;
  align-items: center;
  align-self: center;
  position: absolute;
  left: 2.75vw;
  bottom: 2.75vw;
  /* border: solid; */
  /* border-width: 2px; */
  /* margin-left: 1vw;
  margin-right: 20vw; */
}

img#icon-image {
  /* scale: 90%; */
  object-fit: cover;
}

div.profile-icon-border, div.profile-icon, img#icon-image {
  margin-top: 5vh;
}

h1#username {
  margin-top: max(19vh);
  margin-right: auto;
  margin-left: auto;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 4vw;
}

hr#username-line-divider {
  margin-top: 4vh;
  margin-left: 18.5vw;
  width: 62.5vw;
  align-self: center;
}

div.username-block {
  width: 98vw;
  height: 8vh;
  align-content: center;
}

div.profile-bottom {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

div.profile-skeleton-tab-bar {
  display: flex;
  flex-direction: row;
  margin-top: 9vh;
}

h3.profile-skeleton-tab  {
  font-family: Inter, sans-serif;
  margin-left: 0;
  margin-right: 1.5vw;
  transition: .5s;
  padding: 2vw;
  border-radius: 45px;
}

h3.profile-skeleton-tab:hover  {
  font-family: Inter, sans-serif;
  color: aliceblue;
  background-color: #35374D;
  transition: .5s;
  cursor: pointer;
}

div.profile-top-bottom {
  display: grid;
  grid-template-columns: repeat(2, 45vw);
  column-gap: 0vw;
  row-gap: 4vh;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

div.skeletons-block {
  transition: 0.5s;
  width: 75vw;
  height: 40vh;
  background-color: rgba(188, 208, 228, 0.436);
  border-radius: 25px;
  padding-top: 4vh;
  padding-bottom: 4vh;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
}

ul.skeletons-block-list {
  transition: 0.5s;
  text-align: left;
  width: 75vw;
  height: 35vh;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
  overflow: auto;
  line-height: 5vh;
}
div.bottom-skeletons-block {
  border-radius: 25px;
  width: 77vw;
  height: 40vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 8vh;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(188, 208, 228, 0.436);
}

h2.profile-bottom-title {
  font-family: Inter, sans-serif;
}

.profile-page-skellie-show-link-profile-page {
  font-size: 2vw;
  text-decoration: none;
}

a.profile-page-skellie-show-link-profile-page {
  transition: .25s;
  width: 50vw;
  text-decoration: none;
  color: black;
  font-size: 1.75vw;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  line-clamp: 1;
  margin-bottom: -2vh;
}

#specific-skellie-link:hover {
  transition: .25s;
  color: #979cc6;
}

span#bone-counter {
  color: #545ca3;
  font-size: 1.25vw;
  margin-bottom: -5vh;
}

.profile-skellie-sep {
  width: 60vw;
  margin-left: 0.5vw;
  margin-right: auto;
}

div.edit-delete-div-profile-page {
  margin-top: -2vh;
  margin-bottom: 2vh;
}

.skellie-show-li-div-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}