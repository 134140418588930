div#comment-form-container {
  width: 50vw;
  display: flex;
  flex-direction: column;
  background-color: rgba(188, 208, 228, 0.436);
  height: max-content;
  padding-left: .5vw;
  padding-right: .5vw;
  padding-top: 1.5vh;
  padding-bottom: 2.5vh;
  border-radius: 10px;
  margin-left: 4vw;
  margin-right: auto;
  margin-bottom: 4vh;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
}

h2#comment-section-label {
  font-family: Inter, sans-serif;
  margin-bottom: 2vh;
  font-size: 1.5vw;
  margin-left: 4vw;
  margin-right: auto;
}

#comment-form-label {
  margin-bottom: 2vh;
  font-size: 2.5vw;
  margin-left: 1vw;
  margin-right: auto;
}

textarea#comment-input {
  width: 45vw;
  /* height: 1vh; */
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 1.5vh;
  align-items: center;
  /* justify-content: center; */
  /* justify-items: center; */
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.5vw;
  border: #c3c3c3;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

button#submit-comment-button {
  transition: 0.5s;
  width: min-content;
  height: min-content;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: 1vw;
  margin-left: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
    
button#submit-comment-button:hover {
  cursor: pointer;
  transition: 0.5s;
  background-color: #34374d;
  color: white;
}