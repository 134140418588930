  .form-container {
    text-align: center;
    align-self: center;
    width: 60vw;
    height: max-content;
    margin-top: 8vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8vh;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: rgba(188, 208, 228, 0.436);
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }


  h2.form-title {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    font-size: 3vw;
    text-align: center;
  }


  div.errors {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.5vw;
    justify-content: center;
    text-decoration: underline;
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }


  span.session-label {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    text-align: left;
  }

  h2.session-label-text {
    margin-left: 8vw;
  }

  input.session-input {
    width: 45vw;
    height: 2.75vh;
    margin-left: 2.5vw;
    margin-right: .5vw;
    margin-bottom: 1vw;
    align-items: center;
    /* justify-content: center; */
    /* justify-items: center; */
    background-color: #30303000;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    border: #c3c3c3;
    border-style: solid;
    border-width: 1px;
    border-radius: 75px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  input.session-form-submit-button {
    transition: 0.5s;
    width: 20vw;
    height: 5vh;
    align-items: center;
    margin-top: 2vw;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    border: solid;
    border-width: 0.5px;
    border-color: #34374d2f;
    border-radius: 75px;
    padding-top: 7px;
    padding-bottom: 20px;
    vertical-align: middle;
    margin-top: 4vw;
    margin-bottom: auto;
  }

  input.session-form-submit-button:hover {
    transition: 0.5s;
    background-color: #34374d;
    color: white;
  }