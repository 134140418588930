  div.index-tile-container {
    width: 30vw;
    height: min-content;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: rgba(188, 208, 228, 0.436);
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }

  div.index-tile-container:hover {
    box-shadow: 0px 0px 5px #979cc6;
    transition: .5s;
  }

  div.index-tile-top-container {
    justify-content: space-between;
    display: flexbox;
    vertical-align: center;
  }

  div.index-author-bones-container {
    display: flex;
    margin-top: 1vw;
  }

  div.index-title-container, div.index-author-container {
    width: 30vw;
  }

  div.index-tile-container {
    height: 21vh;
  }

  h1.index-title {
    font-weight: 400;
    font-size: 2.5vw;
  }

  h1.index-author, h1.index-bone-counter, h1.index-author-bone-divider {
    font-weight: 400;
    font-size: 1.5vw;
  }

  h1.index-author-bone-divider {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: -2vh;
  }


  h1.index-title, h1.index-author {
    letter-spacing: .05vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #index-title-link, #index-profile-link {
    text-decoration: none;
    color: black;
  }

  h1.index-title:hover, h1.index-author:hover {
    color: #979cc6;
    transition: .5s;
    cursor: pointer;
  }

  h3.index-bone-counter {
    align-self: center;
  }

  div.index-skeleton-body-likes-container {
    margin-top: -2vh;
    display: flex;
    margin-bottom: 1vh;
  }

  .index-skeleton-body-container {
    margin-top: 4vh;
    font-style: italic;
    width: 45vw;
    color: rgb(42, 42, 42);
    margin-bottom: .25vh; 
  }

  .index-skeleton-body-container {
    margin-top: 3vh;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-bottom: -10.5vw; */
    display: -webkit-box;
    /* margin-bottom: 3vw; */
    -webkit-line-clamp: 2;
      /* number of lines to show */
    line-clamp: 2;
    width: 28vw;
    -webkit-box-orient: vertical;
    font-size: 1.25vw;
    line-height: 4vh;
  }

  span.index-skeleton-body {
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-bottom: -10.5vw; */
    display: -webkit-box;
    /* margin-bottom: 3vw; */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 4vh;
  }

  div.index-skeleton-likes-container {
    display: flexbox;
    scale: 75%;
    margin-left: 1vw;
    margin-right: -2.5vw;
    margin-top: -1vh;
    text-align: center;
    align-content: center;
    vertical-align: center;
  }

  p.index-skeleton-like-count {
    font-size: 2.5vw;
  }

  p.index-skeleton-like-caption {
    font-size: 1.5vw;
    margin-top: 0.75vh;
  }

    button.vote-button {
      aspect-ratio: 1 / 1;
      background-color: none;
      border: none;
      padding-top: 0vh;
      padding-bottom: 0vh;
    }
  
    button#upvote-button {
      margin-top: 1vw;
      transition: .5s;
    }
  
    button#downvote-button {
      margin-top: 0vw;
      transition: .5s;
    }
  
    img.vote-button-image {
      max-width: 2vw;
      max-height: 2vw;
      aspect-ratio: 1 / 1;
    }
  
    button#upvote-button:hover,
    button#downvote-button:hover {
      cursor: pointer;
    }
  
    button#upvote-button>img.vote-button-image {
      transition: .5s;
    }
  
    button#downvote-button>img.vote-button-image {
      transition: .5s;
    }
  
    button#upvote-button:hover>img.vote-button-image {
      filter: invert(75%);
      text-shadow: 2px 2px 2px;
      transition: .5s;
      scale: 100%;
      cursor: pointer;
    }
  
    button#downvote-button:hover>img.vote-button-image {
      filter: invert(75%);
      text-shadow: 2px 2px 2px;
      transition: .5s;
      scale: 100%;
      cursor: pointer;
    }
  button.vote-button {
    aspect-ratio: 1 / 1;
    background-color: none;
    border: none;
    padding-top: 0vh;
    padding-bottom: 0vh;
  }


  div.index-skeleton-tags-container {
    width: 27vw;
    height: 1.75vh;
    /* margin-top: min(5vh); */
    /* margin-bottom: 4vh; */
    /* background-color: rgb(241, 241, 241); */
    padding-top: 2.25vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 2.25vh;
    border-radius: 25px;
  }

  ul.index-skeleton-tags {
    align-self: center;
    margin-top: min(3.5vh);
    margin-right: 2vw;
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 2vh;
    display: inline-flex;
    align-items: row;
    height: 2vh;
    overflow-x: auto;
    overflow-y: none;
    width: 27vw;
    margin-bottom: auto;
    border-radius: 25px;
    background-color: rgb(241, 241, 241);
  }

 
  ul.index-skeleton-tags::-webkit-scrollbar {
    margin-bottom: -2vh;
    height: 4.5px;
    width: 12px;
  }

  ul.index-skeleton-tags::-webkit-scrollbar-track {
    /* margin-top: 4vh; */
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #bdbdbd;
    /* margin-top: 4vh; */
    margin-bottom: 4vh;

  }

  ul.index-skeleton-tags#-webkit-scrollbar-corner {
    background-color: none;
  }

  ul.index-skeleton-tags::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #2e33385b;
  }

  /* ul.index-skeleton-tags ~ li {
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-box-orient: horizontal; */
  /* } */

  p.index-ind-tag {
    font-style: italic;
    margin-right: 1vw;
    font-size: 1.05vw;
    filter: opacity(50%);
  }

  p.index-ind-tag:hover {
    font-style: normal;
    filter: opacity(100%);
    transition: 1s;
    cursor: pointer;
  }

