div.index-page {
  /* background-color: rgb(35, 51, 91); */
  font-family: Arial, sans-serif;
  /* color: white; */
  display: flex;
  justify-content: space-evenly;
}


div.index-content {
  font-family: Arial, sans-serif;
  justify-content: space-evenly;
  margin-top: -4vh;
}