div.skellie-main-container {
  display: inline-flex;
  /* justify-content: space-between; */
  /* flex-direction: row; */
  font-family: Inter, sans-serif;
  /* padding-bottom: 0; */
  /* padding-right: 0; */
  width: 90vw;
  margin-right: 0em;
  height: max-content;
}

div.show-top-middle {
  width: 55vw;
  height: min-content;
  padding-left: 0vw;
  padding-right: 1vw;
  padding-top: 1vh;
  display: flex;
  flex-direction: column;
  /* margin-right: 10vw; */
  padding-bottom: 4vh;
}

div.collaborator-panel {
  /* margin-left: 0vw; */
  text-align: center;
  width: 20vw;
  height: min-content;
  /* min-height: 123vh; */
  margin-top: 4vh;
  margin-left: 5vw;
  text-align: center;
  align-items: center;

  /* background-color: rgba(188, 208, 228, 0.436); */
  /* display: flex; */
  border-radius: 25px;
  transition: .5s;
}

div.collaborator-panel-text {
  width: 18vw;
  align-self: center;
  align-items: center;
  align-content: center;
  height: min-content;
  font-size: 2vw;
  background-color: rgba(188, 208, 228, 0.436);
  min-height: 60vh;
  padding-top: 4vh;
  padding-bottom: 2.75vh;
  padding-left: 3vw;
  padding-right: 3vw;
  margin-left: 12vw;
  border-radius: 25px;
}

ul.collaborators-list {
  text-align: left;
  line-height: 1.25em;
}

div.show-top {
  margin-left: 2vw;
  width: 65vw;
  display: flex;
  margin-top: 0vh;
  flex-direction: column;
  height: min-content;
  padding-left: 4vw;
  padding-right: 5vw;
  /* padding-top: 2vh; */
  padding-bottom: 1vh;
  /* background-color: rgba(188, 208, 228, 0.136); */
  display: flex;
  border-radius: 25px;
  transition: .5s;
}

div.title-user-edit {
  display: flex;
  margin-bottom: 2vh;
}

h1#skeleton-title {
  font-size: 2vw;
  font-weight: 600;
  max-width: 50vw;
  margin-top: auto;
  margin-bottom: auto;
}

span#bone-counter-in-title {
  font-size: 2vw;
  font-weight: 600;
  max-width: 20vw;
}

hr#edit-delete-title-divider {
  margin-left: 2vw;
  margin-right: 3vw;
}

div.edit-delete-div {
  display: flex;
  flex-direction: column;
  z-index: 4;
}

button.comment-update-button, button.comment-delete-button {
  width: 6vw;
  transition: 0.5s;
  height: min-content;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

button.comment-update-button:hover {
  cursor: pointer;
  transition: 0.5s;
  background-color: rgb(59, 116, 173);
  color: white;
}

button.comment-delete-button:hover {
  cursor: pointer;
  transition: 0.5s;
  background-color: rgb(118, 41, 29);
  color: white;
}


div.sub-title {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

h3#skeleton-owner {
  font-size: 2.5vw;
}

a.skeleton-show-profile-link {
  text-decoration: none;
  transition: .5s;
}

a.skeleton-show-profile-link:hover {
  text-decoration: none;
  filter: invert(100%);
  transition: .5s;
}

a.skeleton-show-profile-current-collab-link {
  text-decoration: none;
  transition: .5s;
}

a.skeleton-show-profile-current-collab-link:hover {
  text-decoration: none;
  filter: invert(100%);
  transition: .5s;
}

h3#skeleton-prompt {
  margin-top: .25vh;
  font-size: 1.5vw;
  font-style: italic;
  margin-left: 1vw;
  width: 45vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  opacity: 45%;
}

span#name-prompt-divider {
  margin-left: .5vw;
  margin-right: 1vw;
}

div.show-middle {
  margin-left: 4vw;
  width: 70vw;
  height: max-content;
  justify-content: space-between;
  min-height: 60vh;
  /* padding-left: 4vw;
  padding-right: 4vw; */
  padding-top: 1vh;
  padding-bottom: -2vh; 
  /* background-color: rgba(188, 208, 228, 0.436); */
  display: flex;
  border-radius: 25px;
  transition: .5s;
}

div.skeleton-body-input-container {
  margin-left: 4vw;
  /* height: max-content; */
  height: max-content;
  /* min-height: 80vh; */
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background-color: rgba(188, 208, 228, 0.436);
  border-radius: 25px;
  transition: .5s;
}

div.skeleton-body-input-container {
  width: 58vw;
  margin-left: 0vw;
  /* margin-right: 4vw; */
}

div.collaborator-panel {
  /* margin-left: 4vw; */
  width: 10vw;
  z-index: 0;
}

div#skeleton-body {
  display: inline-block;
  font-size: 1.5vw;
  line-height: 5vh;
  margin-bottom: 5vh;
}

hr#body-input-divider {
  margin-bottom: 5vh;
}

div.user-input-div {
  align-items: center;
  align-self: center;
}

div#current-writer-note {
  align-self: center;
  text-align: center;
  margin-bottom: 5vh;
}

span#current-writer-username {
  font-style: oblique;
  font-weight: 700;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

textarea#current-collab-input {
  width: 45vw;
  height: 15vh;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 2vw;
  align-items: center;
  /* justify-content: center; */
  /* justify-items: center; */
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.5vw;
  border: #c3c3c3;
  border-style: solid;
  border-width: 1px;
  border-radius: 3vw;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

div.horizontal-skeleton-likes-container {
  display: flex;
  width: min-content;
  flex-direction: row;
  vertical-align: middle;
  background-color: #dcd6f0;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: center;
  margin-bottom: 1vh;
  border-radius: 20px;
}

#skeleton-show-votes {
  margin-left: 1vw;
  margin-right: 1vw;
}

#skeleton-show-votes {
  transition: .5;
}

#new-bone-form {
  align-items: center;
  text-align: center;
  margin-bottom: 2vh;
}

button.skeleton-show-downvote, button.skeleton-show-upvote, .skeleton-show-upvote, .skeleton-show-downvote {
  border: none;
  background-color: transparent;
  margin-top: 1vh;
  transition: .25s;
}

button.skeleton-show-downvote:hover, button.skeleton-show-upvote:hover, .skeleton-show-upvote:hover, .skeleton-show-downvote:hover {
  cursor: pointer;
  filter: invert(25%);
  transition: .25s;
}

hr#comment-divider {
  width: 85%;
  margin-bottom: 2vh;
  opacity: 45%;
}

#bone-submit-button {
  transition: 0.5s;
  width: max-content;
  height: min-content;
  align-content: center;
  align-items: center;
  margin-top: .5vh;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1vw;
  vertical-align: text-top;
  text-decoration: none;
  font-weight: 400;
  border: solid;
  border-width: 0.5px;
  border-color: #34374d49;
  border-radius: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

#bone-submit-button:hover {
  cursor: pointer;
  transition: 0.5s;
  background-color: #34374d;
  color: white;
}


.downVote-grey-color {
  color: grey;
  padding-top: 10px;
}

.upVote-grey-color {
  color: grey;
  padding-top: 10px;
}

#skeleton-show-votes {
  padding-top: 10px;
}