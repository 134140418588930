  .form-container {
    text-align: center;
    align-self: center;
    width: 60vw;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: rgba(188, 208, 228, 0.436);
    display: flexbox;
    border-radius: 25px;
    transition: .5s;
  }

  h2.form-title {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    font-size: 3vw;
    text-align: center;
    margin-bottom: -2vw;
  }

  div.errors {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.5vw;
    justify-content: center;
    text-decoration: underline;
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

  span.skellie-label {
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    text-align: left;
  }

  h2.skellie-label-text {
    margin-left: 8vw;
  }

  input.skellie-input {
    width: 45vw;
    height: 2.75vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2vw;
    align-items: center;
    /* justify-content: center; */
    /* justify-items: center; */
    background-color: #30303000;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    border: #c3c3c3;
    border-style: solid;
    border-width: 1px;
    border-radius: 75px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  textarea.skellie-input {
    margin-left: auto;
    margin-right: auto;
    background-color: #30303000;
    border-radius: 15px;
    width: 43vw;
    height: 3em;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border: #c3c3c3;
    border-style: solid;
    border-width: 1px;
    align-self: center;
    /* margin-left: 2vw;
    margin-right: 2vw; */
    z-index: 0;
  }

  #collaborator-select {
    /* margin-left: auto;
    margin-right: auto; */
    width: 10vw;
    /* max-width: 27em; */
    height: 5em;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 5vh;
    margin-bottom: 10vh;
  }

  p#credit-to-website {
    margin-top: 2vh;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1vw;
    opacity: 55%;
    font-style: italic;
    margin-bottom: -3vh;
  }

  a#random-word-link {
    text-decoration: underline;
    color: black;
    transition: .5s;
  }

  a#random-word-link:hover {
    color: #454c83;
    font-weight: 700;
    transition: .5s;
  }

  input.skellie-input, textarea.skellie-input {
    align-items: center;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    margin-bottom: -2vh;
  }

  div.multiselect-container#collaborator-select {
    margin-top: -2vh;
  }
  div.multiselect-container, div.search-wrapper {
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 20vw;
    height: 4vh;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 5vh;
    border-radius: 25px;
  }

#collaborator-select {
  margin-left: 0;
  /* margin-right: auto; */
  width: 20vw;
  height: max-content;
  padding-bottom: 2vh;
  border-radius: 25px;
  margin-bottom: 2vh;
}

div.multiselect-container, ul.optionContainer {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: auto;
  margin-right: auto;
  width: 48vw;
  /* width: 20vw; */
  height: 20vh;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
}

/* problem not this */
div.search-wrapper.searchWrapper {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: auto;
  margin-right: auto;
  width: 48vw;
  height: 4vh;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 5vh;
  border-radius: 25px;
}

div.search-wrapper.searchWrapper {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* margin-left: 0vw; */
}

div#collaborator-select.multiselect-container.multiSelectContainer {
  font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-self: center;
  margin-right: 20vw;
  /* margin-left: auto; */
  /* margin-right: auto; */
}


textarea.skellie-input::-webkit-scrollbar {
    margin-top: 4vh;
    height: 2px;
    width: 8px;
    z-index: 99999;
  }

  textarea.skellie-input::-webkit-scrollbar-track:vertical {
    margin-top: 4vh;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #bdbdbd;
    margin: 5px ;
    margin-right: 2vw;
    z-index: 99999;
  }

  textarea.skellie-input::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #2e33385b;
    z-index: 99999;
  }

  textarea.skellie-input::-webkit-scrollbar-corner {
    background-color: none;
    border: none;
  }

  textarea.skellie-input::-webkit-scrollbar-resizer {
    background-color: none;
    border: none;
  }

  button#random-prompt-button {
    transition: 0.5s;
    width: 30vw;
    height: 4vh;
    align-items: center;
    vertical-align: middle;
    background: none;
    margin-top: 2vw;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    border: solid;
    border-width: 0.5px;
    border-color: #34374d5c;
    border-radius: 75px;
    margin-top: 4vh;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    
  }

  button#random-prompt-button:hover {
    transition: 0.5s;
    background-color: #34374d;
    color: white;
  }

  input.skellie-form-submit-button {
    transition: 0.5s;
    width: 30vw;
    height: 8vh;
    align-items: center;
    margin-top: 2vw;
    font-family: 'Open Sans', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
    font-weight: 400;
    border: solid;
    border-width: 0.5px;
    border-color: #34374d5c;
    border-radius: 75px;
    margin-top: 5vh;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  input.skellie-form-submit-button:hover {
    transition: 0.5s;
    background-color: #34374d;
    color: white;
  }