/* TODO Add site wide styles */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
	font-family: "Inter";
	src: local('Inter-VariableFont_slnt,wght'),
		url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
	font-family: "Open Sans";
	src: local('OpenSans-VariableFont_wdth,wght'),
	url("./fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


form > .errors {
  color: red;
  font-size: 0.875em;
  height: 15px;
  display: flex;
  align-items: center;
}

	::-webkit-scrollbar {
		margin-top: 4vh;
		height: 3.5px;
		width: 10px;
	  }
	
	::-webkit-scrollbar-track {
		margin-top: 4vh;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background-color: #bdbdbd;
		margin-top: 4vh;
		margin-bottom: 4vh;
		margin-right: 1vw;
	}

	::-webkit-scrollbar-corner {
		background-color: none;
	}
	::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: #2e33385b;
	}